// Captcha Script Start Here

// Function to generate a random code
function generateCode() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const length = 6;
  let code = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }

  return code;
}

// Function to create an image from the code
function createCaptchaImage() {
  const code = generateCode();
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = 150;
  canvas.height = 50;

  // Customize styles as needed
  context.font = '30px Arial';
  context.fillStyle = '#fff';
  context.fillText(code, 20, 40);

  document.getElementById('captchaImage').innerHTML = '';
  document.getElementById('captchaImage').appendChild(canvas);

  // Store the code in a variable for validation
  window.captchaCode = code;
}

window.onload = createCaptchaImage;

function validateEmail(email) {
  if (!email || email.trim() === "") {
    alert("Please add your email ID");
    return false;
  }

  if (!email.includes('@')) {
    alert("Please add '@' in your email");
    return false;
  }

  if (!email.endsWith(".com")) {
    alert("Email address must end with '.com'. Please enter a valid email address.");
    return false;
  }

  return true;
}

// Country Code Implementation
let contactVal = document.getElementById("Phone_number");
let countryCodeVal = document.getElementById("country_code");

function formatPhoneNumber() {
  let selectedCode = countryCodeVal.value;
  let phoneNumber = contactVal.value.trim().replace(/\D/g, ""); // Remove non-numeric characters

  // If phone number is empty, return only the country code (no alert)
  if (phoneNumber === "") {
    return null;
  }

  // Remove any existing country code before appending new one
  phoneNumber = phoneNumber.replace(/^\+\d+/, '');

  // Ensure phone number has at least 9 digits (excluding country code)
  if (phoneNumber.length < 9) {
    alert("Please enter a valid phone number with at least 9 digits.");
    return "";
  }

  return selectedCode + phoneNumber;
}

// Ensure phone number updates properly when selecting a country code
countryCodeVal.addEventListener("change", function () {
  let formattedNumber = formatPhoneNumber();
  if (formattedNumber) {
    contactVal.value = formattedNumber;
  }
});

// Captcha Script Finish Here
let nameVal = document.getElementById("First_Name");
let emailVal = document.getElementById("Email");
let companyVal = document.getElementById("Company_Name");
let messageVal = document.getElementById("Message");
let capchaVal = document.getElementById("captchaInput");

async function FormSubmit(event) {
  event.preventDefault();

  if (!validateEmail(emailVal.value)) {
    console.log("Email is invalid.");
    return;
  }

  if (!validateCaptcha()) return;
  function validateCaptcha() {
    const userInput = document.getElementById('captchaInput').value;
    if (userInput !== window.captchaCode) {
      alert('Incorrect captcha code. Please try again.');
      createCaptchaImage();
      return false;
    }
    createCaptchaImage();
    return true;
  }

  let submitBtn = document.getElementById("form-button");
  submitBtn.setAttribute("editable", true);
  const formD = new FormData();

  // Format phone number correctly before appending
  let formattedPhone = formatPhoneNumber();
  

  if (formattedPhone.length < 10 || formattedPhone.length > 18) {
    alert("Please enter a valid phone number with at least 9 digits.");
    return;
  }
  // console.log('ee',formattedPhone)
  formD.append("phone", formattedPhone);
  formD.append("name", nameVal.value);
  formD.append("email", emailVal.value);
  formD.append("company_name", companyVal.value);
  formD.append("description", messageVal.value);
  formD.append("source", "contact-us");

  const options = {
    method: "POST",
    body: formD,
  };

  try {
    const resp = await fetch(`${process.env.APIURL}/blog/api/contact-us`, options);
    if (!resp.ok) throw Error("Something went wrong");

    window.location.assign("/thankyou");

    // Clear form fields
    nameVal.value = "";
    emailVal.value = "";
    companyVal.value = "";
    contactVal.value = "";
    messageVal.value = "";
    capchaVal.value = "";

    submitBtn.setAttribute("editable", false);
  } catch (error) {
    console.log(error);
  } 
}

window.FormSubmit = FormSubmit;
window.generateCode = generateCode;
window.createCaptchaImage = createCaptchaImage;
